import React from "react";
import Seo from "../../../components/seo";
import HasuraConHeader from "../../../components/hasuracon/hasuraconheader";
import "../../../components/hasuracon/styles.scss";
import CocTopBanner from "../../../components/hasuracon/coctopbanner";
import Footer from "../../../components/common/footer";
import HasuraConFooter from "../../../components/hasuracon/hasuraconfooter";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-card.png",
};
const canonicalUrl = "https://hasura.io/events/hasura-con-2020/code-of-conduct/";
const HasuraCon = props => (
  <div id="viewport" className="wd100 viewport">
    <div className="blueGradientBgColor">
      <Seo
        title="HasuraCon'20 | Code of Conduct"
        description="Code of Conduct for HasuraCon'20. HasuraCon is a free, online conference. Join us for a week of talks and hand-on workshops about pushing the boundaries of GraphQL forward!"
        meta={ogImage}
        canonicalLink={canonicalUrl}
      />
      <HasuraConHeader location={props.location} />
      <CocTopBanner />
      <HasuraConFooter location={props.location} />
    </div>
    <Footer location={props.location} />
  </div>
);

export default HasuraCon;
