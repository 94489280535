import React from "react";
import { Link } from "gatsby";
// import PropTypes from "prop-types"
import "./styles.scss";
import hasConLogo from "./images/has-con-logo.svg";
import june from "./images/june.svg";
import pattern from "./images/pattern.svg";
import back from "./images/back.svg";
const CocTopBanner = () => {
  return (
    <section className="commonSectionWrapper topBannerWrapperHasuraConRel addTopBannerPaddRegister">
      <div className="containerWrapper">
        <div className="topBannerWrapperHasuraCon">
          <div className="col-md-6 col-sm-8 col-xs-12 noPadd">
            <div className="backHasuraConf">
              <Link to="/events/hasura-con-2020/">
                <img src={back} alt="back" />
                <span>Conference page</span>
              </Link>
            </div>
            <div className="frameCon">
              <img src={hasConLogo} alt="Hasuracon20" />
            </div>
            <div className="date textLeft">
              <img src={june} alt="Date" />
            </div>
            <h1 className="title textLeft">Code of Conduct</h1>
            <div className="bodyTextSmallCon">
              All attendees, speakers, sponsors and volunteers at HasuraCon 2020 are required to
              agree with the following code of conduct. Organisers will enforce this code throughout
              the event. We expect cooperation from all participants to help ensure a safe
              environment for everybody.
              <br />
              <br />
              In case you have identified a breach of our CoC, please send an email addressed to{" "}
              <span className="fontBold">Rajoshi Ghosh</span> with the subject{" "}
              <span className="fontBold">Reporting CoC Violation for HasuraCon 2020</span>{" "}
              immediately to <a href="mailto:events@hasura.io">events@hasura.io</a>.
            </div>
            <h3 className="smallParaHeaderCon">The Short Version</h3>
            <div className="bodyTextSmallCon">
              Our conference is dedicated to providing a harassment-free conference experience for
              everyone, regardless of gender, gender identity and expression, age, sexual
              orientation, disability, physical appearance, body size, race, ethnicity, religion (or
              lack thereof), or technology choices. We do not tolerate harassment of conference
              participants in any form. Sexual language and imagery is not appropriate for any
              conference venue, including talks, workshops, parties, Twitter and other online media.
              Conference participants violating these rules may be expelled from the conference
              without a refund at the discretion of the conference organisers.
            </div>
            <h3 className="smallParaHeaderCon">The Long Version</h3>
            <div className="bodyTextSmallCon">
              Harassment includes offensive verbal comments related to gender, gender identity and
              expression, age, sexual orientation, disability, physical appearance, body size, race,
              ethnicity, religion, technology choices, sexual images in public spaces, deliberate
              intimidation, stalking, following, harassing photography or recording, sustained
              disruption of talks or other events, inappropriate physical contact, and unwelcome
              sexual attention.
              <br />
              <br />
              Participants asked to stop any harassing behavior are expected to comply immediately.
              <br />
              <br />
              Sponsors are also subject to the anti-harassment policy. In particular, sponsors
              should not use sexualised images, activities, or other material. Booth staff
              (including volunteers) should not use sexualised clothing/uniforms/costumes, or
              otherwise create a sexualised environment.
              <br />
              <br />
              If a participant engages in harassing behavior, the conference organisers may take any
              action they deem appropriate, including warning the offender or expulsion from the
              conference with no refund.
              <br />
              <br />
              If you are being harassed, notice that someone else is being harassed, or have any
              other concerns, please contact a member of conference staff immediately - please see
              the section below on how to report CoC violations.
              <br />
              <br />
              Conference staff will be happy to assist those experiencing harassment to feel safe
              for the duration of the conference. We value your attendance.
              <br />
              <br />
              We expect participants to follow these rules at all times during conference events and
              workshop venues and conference-related social events.
              <br />
              <br />
              Original source and credit:{" "}
              <a href="http://2012.jsconf.us/#/about" target="_blank" rel="noopener noreferrer">
                http://2012.jsconf.us/#/about
              </a>{" "}
              &{" "}
              <a
                href="https://geekfeminism.wikia.org/wiki/Conference_anti-harassment/Policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                The Ada Initiative
              </a>
              <br />
              <br />
              This work is licensed under a Creative Commons Attribution 3.0 Unported License
            </div>
          </div>
        </div>
      </div>
      <div className="patternImg">
        <img src={pattern} alt="Pattern" />
      </div>
    </section>
  );
};

export default CocTopBanner;
